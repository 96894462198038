<template>
  <div id="modal-feedback-form" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form ref="feedbackForm" @submit.prevent="sendFeedback">
          <div class="modal-body" v-if="formSent === false">
            <h3 class="mb-4">{{ form.description[$i18n.locale] }}</h3>

            <div class="d-flex flex-column mb-5" v-for="(question, index) of form.questions" :key="question.id">
              <template v-if="question.question_type === 'number'">
                <div class="d-flex font-weight-bold" :class="{ 'text-danger': errors.includes(question.id) }">{{ question.question[$i18n.locale] }}</div>

                <div class="btn-toolbar w-100" role="toolbar">
                  <div class="btn-group btn-group-toggle w-100 d-flex justify-content-center align-items-center p-0" role="group">
                    <label class="btn min-h-px-40 h-px-40 px-0" :class="{ 'btn-primary': answers[question.id] === number - 1, 'btn-secondary': answers[question.id] !== number - 1 }" v-for="number in 11" :key="number">
                      <input type="radio" autocomplete="off" :value="number - 1" :name="question.id" v-model="answers[question.id]"> {{ number - 1 }}
                    </label>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="text-danger" v-if="typeof question.text_zero !== 'undefined' && typeof question.text_zero[$i18n.locale] !== 'undefined'">{{ question.text_zero[$i18n.locale] }}</div>
                  <div class="ml-auto text-green text-right" v-if="typeof question.text_ten !== 'undefined' && typeof question.text_ten[$i18n.locale] !== 'undefined'">{{ question.text_ten[$i18n.locale] }}</div>
                </div>
              </template>
              <template v-if="question.question_type === 'custom_number'">
                <div class="d-flex font-weight-bold" :class="{ 'text-danger': errors.includes(question.id) }">{{ question.question[$i18n.locale] }}</div>

                <div class="btn-toolbar w-100" role="toolbar">
                  <div class="btn-group btn-group-toggle w-100 d-flex justify-content-center align-items-center p-0" role="group">
                    <label class="btn min-h-px-40 h-px-40 px-0" :class="{ 'btn-primary': answers[question.id] === number - 1, 'btn-secondary': answers[question.id] !== number - 1 }" v-for="number in question.max_range + 1" :key="number">
                      <input type="radio" autocomplete="off" :value="number - 1" :name="question.id" v-model="answers[question.id]"> {{ number - 1 }}
                    </label>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="text-danger" v-if="typeof question.text_zero !== 'undefined' && typeof question.text_zero[$i18n.locale] !== 'undefined'">{{ question.text_zero[$i18n.locale] }}</div>
                  <div class="ml-auto text-green text-right" v-if="typeof question.text_ten !== 'undefined' && typeof question.text_ten[$i18n.locale] !== 'undefined'">{{ question.text_ten[$i18n.locale] }}</div>
                </div>
              </template>
              <template v-if="question.question_type === 'input'">
                <div class="d-flex font-weight-bold" :class="{ 'text-danger': errors.includes(question.id) }">{{ question.question[$i18n.locale] }}</div>

                <div class="d-flex justify-content-center align-items-center p-0">
                  <input type="text" class="form-control" :id="'question_input_' + index" :name="question.id" :ref="'question_input_' + index" :required="question.required" v-model="answers[question.id]" :placeholder="$t('Modals/FeedbackForm.Enter your answer')">
                </div>
              </template>
              <template v-if="question.question_type === 'dropdown'">
                  <div class="d-flex font-weight-bold" :class="{ 'text-danger': errors.includes(question.id) }">{{ question.question[$i18n.locale] }}</div>

                  <div class="d-flex justify-content-center align-items-center p-0">
                    <select class="form-control" v-model="answers[question.id]">
                      <template v-for="(option_text, option_index) of question.question_options">
                        <option :key="option_index" :value="option_text.value">{{ option_text.labels[$i18n.locale] }}</option>
                      </template>
                    </select>
                  </div>
              </template>
            </div>
          </div>
          <div class="modal-body" v-else>
            <h3 class="mb-4">{{ $t('Modals/FeedbackForm.Feedback sent') }}</h3>
            <p>
              {{ $t('Modals/FeedbackForm.Thank you for filling out the form') }}
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="cancelForm" v-if="formSent === false">{{ $t('Modals/FeedbackForm.No, cancel') }}</button>
            <button type="submit" class="btn btn-primary" v-if="formSent === false">{{ $t('Modals/FeedbackForm.Send feedback') }}</button>

            <button type="button" class="btn btn-secondary" @click="hideForm" v-if="formSent === true">{{ $t('Modals/FeedbackForm.Close') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackForm',
  props: ['form'],
  data () {
    return {
      answers: {},
      errors: [],
      formSent: false
    }
  },
  methods: {
    async sendFeedback () {
      for (let question of this.form.questions) {
        if (typeof question.required !== 'undefined' && question.required === true) {
          if (typeof this.answers[question.id] === 'undefined' || this.answers[question.id] === '') {
            if (!this.errors.includes(question.id)) {
              this.errors.push(question.id)
            }
          } else {
            let errorIndex = this.errors.indexOf(question.id)

            if (errorIndex > -1) {
              this.errors.splice(errorIndex, 1)
            }
          }
        }
      }

      if (this.errors.length > 0) {
        return false
      }

      let postData = {
        meeting: this.$store.getters.getLastMeetingInfo.id,
        // participant: this.$store.getters.getLastMeetingInfo.participant,
        answers: []
      }

      if (this.$store.getters.getUser !== false) {
        if (typeof this.$store.getters.getLastMeetingInfo.user !== 'undefined') {
          postData.user = this.$store.getters.getLastMeetingInfo.user
        }
      } else {
        postData.participant = this.$store.getters.getLastMeetingInfo.participant
      }

      for (let question of this.form.questions) {
        if (typeof this.answers[question.id] !== 'undefined') {
          postData.answers.push({
            question: question.id,
            answer: this.answers[question.id]
          })
        } else {
          postData.answers.push({
            question: question.id,
            answer: null
          })
        }
      }
      postData.company = this.form.company
      console.log('postdata', postData)
      if (this.$store.getters.getUser !== false) {
        this.$axios.post(this.$axios.routes.feedback + '/' + this.form.id + '/answer', postData)
      } else {
        this.$axiosParticipant.post(this.$axiosParticipant.routes.feedback + '/' + this.form.id + '/answer', postData)
      }

      this.formSent = true
    },
    async cancelForm () {
      let postData = {
        meeting: this.$store.getters.getLastMeetingInfo.id,
        participant: this.$store.getters.getLastMeetingInfo.participant,
        answers: []
      }

      if (typeof this.$store.getters.getLastMeetingInfo.user !== 'undefined') {
        postData.user = this.$store.getters.getLastMeetingInfo.user
      }

      for (let question of this.form.questions) {
        postData.answers.push({
          question: question.id,
          answer: null
        })
      }

      if (this.$store.getters.getUser !== false) {
        this.$axios.post(this.$axios.routes.feedback + '/' + this.form.id + '/answer', postData)
      } else {
        this.$axiosParticipant.post(this.$axiosParticipant.routes.feedback + '/' + this.form.id + '/answer', postData)
      }

      this.hideForm()
    },
    hideForm () {
      // Note: this order of run is important, do not change. jQuery can not close it anymore when the form is gone earlier, what will lead to a blackscreen overlay
      this.$('#modal-feedback-form').modal('hide')
      this.$store.commit('removeFeedbackFormById', this.form.id)
      this.resetVue()
    },
    resetVue () {
      Object.assign(this.$data, this.$options.data.call(this))
    }
  },
  destroyed () {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
