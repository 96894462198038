const Moment = require('moment-timezone')
const { extendMoment } = require('moment-range')

const moment = extendMoment(Moment)

moment.updateLocale('en', {
  week: { dow: 1 } // Monday is the first day of the week
})

module.exports = moment
