<script>
/**
 * This Mixin is used with some custom dialogs, such as the ZynyoEmailRequest, Zynyo and ScriptixTranscription dialog.
 * it groups common functionality for these dialogs.
 */
export default {
  name: 'DialogMixin',
  data () {
    return {
      dialogTop: 0,
      isDialogContentVisible: true
    }
  },
  computed: {
    loaderStyle () {
      // Compute the circular loader style based on the progress value
      return {
        background: `conic-gradient(#9fa1a3 ${this.progress * 3.6}deg, #f0f3f5 0deg)`,
        display: this.progress < 100 ? 'block' : 'none'
      }
    },
    dialogStyle () {
      const dialogBodyHeight = 60

      return {
        transform: `translate(0px, -${dialogBodyHeight * this.index + this.index}px)`,
        zIndex: this.isDialogContentVisible ? this.highestZIndex : 5
      }
    },
    highestZIndex () {
      // Find the highest zIndex among all dialogs
      const dialogs = document.querySelectorAll('.dialog')
      let highest = 5
      dialogs.forEach(dialog => {
        const zIndex = parseInt(window.getComputedStyle(dialog).zIndex, 10)
        if (zIndex > highest) {
          highest = zIndex
        }
      })
      return highest + 1
    }
  },
  methods: {
    toggleDialogContent () {
      this.isDialogContentVisible = !this.isDialogContentVisible
      this.$refs.expandButton.style.transform = this.isDialogContentVisible ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }
}
</script>
<style lang="scss">
@media (min-width: 600px) {
    .dialog {
        width: 60% !important;
    }
}

@media (min-width: 1200px) {
    .dialog {
        width: 26% !important;
        right: 1rem !important;
        left: initial !important;
    }
}

@media (max-width: 500px) {
    .dialog {

      box-shadow: none !important;
    }
}
.dialog {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 120px;
    z-index: 5;
    border-radius: 5px;

    left: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    padding-bottom: env(safe-area-inset-bottom);
    .close {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
}

.dialog-body {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    height: 60px;
    width: 100%;
    overflow: hidden;
    transition: height 0.5s ease;
    &.expanded {
        height: auto;
    }

    .dialog-header {
        display: flex;
        padding: .9rem;
        padding-left: 10px;
        justify-content: space-between;
        align-items: center;

        h6 {
            margin: 0;
        }

        div {
            width: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        position: relative;
        gap: 1rem;
        padding: .9rem;
        button,input {
            padding: 0.2rem .5rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 0.8rem;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .loader {
        position: absolute;
        left: 21px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: conic-gradient(#9fa1a3 0deg, #f0f3f5 0deg);
        transition: background 0.3s ease;
        /* Smooth transition for loader */
        top: 35%;
        border: 2px solid #9fa1a3
    }
    .font-sm {
        font-size: 0.8rem;
    }
}
</style>
