<template>
  <div class="tt-wrapper" @blur.prevent="removeTooltip" @focus.prevent="displayTooltip" @mouseover.prevent="displayTooltip" @mouseleave.prevent="removeTooltip"
  @keydown.enter.prevent="emitFunction" @click="preventClick ? removeTooltip() : emitFunction()">
    <slot></slot>
    <div :style="{ marginRight: showTooltip && allowSpace ? '60px': '0px', pointerEvents: 'none' }">
      <vue-easy-tooltip v-model="showTooltip" withArrow position="top" backgroundColor="#e8edef"
        :styles="{ color: '#073c58', 'font-size': '16px', marginTop: '-35px' }">
        {{ tooltipText }}
      </vue-easy-tooltip>
    </div>
  </div>

</template>

<script>
import VueEasyTooltip from 'vue-easy-tooltip'

let clearerTooltip
export default {
  name: 'TooltipWrapper',
  props: ['tooltipText', 'allowSpace', 'preventClick'],
  data () {
    return {
      showTooltip: false
    }
  },
  components: {
    VueEasyTooltip
  },
  methods: {
    emitFunction () {
      this.showTooltip = false
      this.$emit('emitFunction')
    },
    removeTooltip () {
      this.showTooltip = false
    },
    displayTooltip () {
      clearTimeout(clearerTooltip)
      this.showTooltip = true
      clearerTooltip = setTimeout(() => {
        this.showTooltip = false
      }, 2000)
    }
  }
}

</script>
