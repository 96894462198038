const lamejs = require('./lame.all')

class WebmToMp3Converter {
  /**
   * Convert a WebM blob to MP3
   * @param {Blob} webmBlob - The input WebM blob
   * @param {Object} [options] - Conversion options
   * @param {number} [options.bitRate=128] - MP3 bit rate
   * @param {number} [options.maxFileSizeMB=50] - Maximum file size in MB
   * @returns {Promise<Blob>} - Promise resolving to MP3 blob
   */
  static async convert (webmBlob, options = {}) {
    return new Promise((resolve, reject) => {
      // File size check
      const { bitRate = 128 } = options

      // Validate lamejs is available
      if (typeof lamejs === 'undefined') {
        reject(new Error('lamejs library is not loaded. Please include lame.min.js before this script'))
        return
      }

      // Create audio context
      const audioContext = new (window.AudioContext || window.webkitAudioContext)()

      // Create file reader
      const reader = new FileReader()

      reader.onload = async (e) => {
        try {
          // Decode the audio data with a timeout to prevent freezing
          const arrayBuffer = e.target.result
          const audioBuffer = await Promise.race([
            audioContext.decodeAudioData(arrayBuffer),
            new Promise((resolve, reject) =>
              setTimeout(() => reject(new Error('Audio decoding timed out')), 10000)
            )
          ])

          // Prepare samples and determine number of channels
          const channels = audioBuffer.numberOfChannels
          const sampleRate = audioBuffer.sampleRate

          console.log('Audio Buffer Details:', {
            channels,
            sampleRate,
            duration: audioBuffer.duration,
            fileSize: webmBlob.size
          })

          // Prepare float samples
          const floatSamples = []
          for (let i = 0; i < channels; i++) {
            floatSamples.push(audioBuffer.getChannelData(i))
          }

          // Convert to Int16 samples
          const int16Samples = floatSamples.map(channel => {
            const int16 = new Int16Array(channel.length)
            for (let j = 0; j < channel.length; j++) {
              // Convert float to 16-bit integer
              int16[j] = Math.max(-32768, Math.min(32767, Math.floor(channel[j] * 32768)))
            }
            return int16
          })

          // Create MP3 encoder
          const mp3encoder = new lamejs.Mp3Encoder(
            channels, // number of channels
            sampleRate, // sample rate
            bitRate // bit rate
          )

          const mp3Data = []
          const sampleBlockSize = 1152 // Standard block size for MP3 encoding

          // Encode in blocks with progress tracking
          for (let i = 0; i < int16Samples[0].length; i += sampleBlockSize) {
            // Yield to main thread periodically to prevent freezing
            if (i % (sampleBlockSize * 10) === 0) {
              await new Promise(resolve => setTimeout(resolve, 0))
            }

            // Prepare channel chunks
            const channelChunks = int16Samples.map(channel =>
              channel.subarray(i, i + sampleBlockSize)
            )

            // Encode the block
            let mp3buf
            if (channels === 1) {
              // Mono
              mp3buf = mp3encoder.encodeBuffer(channelChunks[0])
            } else if (channels === 2) {
              // Stereo
              mp3buf = mp3encoder.encodeBuffer(channelChunks[0], channelChunks[1])
            }

            // Add to MP3 data if buffer is not empty
            if (mp3buf.length > 0) {
              mp3Data.push(mp3buf)
            }
          }

          // Flush remaining data
          const mp3buf = mp3encoder.flush()
          if (mp3buf.length > 0) {
            mp3Data.push(mp3buf)
          }

          // Create final MP3 blob
          const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' })
          resolve(mp3Blob)
        } catch (error) {
          console.error('Conversion error:', error)
          reject(error)
        }
      }

      reader.onerror = (error) => reject(error)

      // Read the WebM blob
      reader.readAsArrayBuffer(webmBlob)
    })
  }

  static async blobToBase64 (blob) {
    const buffer = await blob.arrayBuffer()
    const bytes = new Uint8Array(buffer)
    const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '')
    return btoa(binary)
  }
}

// Improved conversion handler
async function handleWebmToMp3Conversion (webmBlob) {
  // Check if lamejs is available
  if (typeof lamejs === 'undefined') {
    console.error('lamejs library is required. Please include lame.all.js')
    return
  }

  try {
    console.log('Starting conversion of WebM blob', {
      size: webmBlob.size,
      type: webmBlob.type
    })

    // Add a progress indicator or loading state here
    const mp3Blob = await WebmToMp3Converter.convert(webmBlob, {
      bitRate: 128, // Optional: 128 kbps
      maxFileSizeMB: 50 // Prevent converting extremely large files
    })

    console.log('Conversion successful. MP3 Blob created:', {
      size: mp3Blob.size,
      type: mp3Blob.type
    })

    const mp3Base64 = await WebmToMp3Converter.blobToBase64(mp3Blob)
    return mp3Base64
  } catch (error) {
    console.error('Conversion failed:', error)
  }
}
module.exports = {
  handleWebmToMp3Conversion
}
