<template>
  <div class="container-fluid d-flex flex-column p-0">
    <weseedo-header></weseedo-header>

    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-10 col-sm-8 col-md-6 col-lg-5">
          <h2 class="font-weight-normal text-center border-bottom pb-2 mb-4">{{ $t('Login.Login') }}</h2>
          <form v-if="!mfaRequired" method="post" @submit.prevent="validateLogin">
            <div class="form-group">
              <label for="email" class="mb-0 font-weight-bold">{{ $t('Login.Username') }}</label>
              <input spellcheck="false" type="email" class="form-control" id="email" name="email" ref="email" v-model="username" tabindex="1">
            </div>
            <div class="form-group mt-4">
              <span class="float-right mb-0"><router-link :to="{ name: 'forgot_password'}">{{ $t('Login.Forgot?') }}</router-link></span>
              <label for="password" class="mb-0 font-weight-bold">{{ $t('Login.Password') }}</label>
              <vue-password v-model="password"
                            classes="form-control"
                            id="password"
                            ref="password"
                            spellcheck="false"
                            tabindex="2"
                            disable-strength
                            @blur="disableViewPassword('password')"
              />
            </div>
            <div class="form-row mb-2" v-show="showForceLogout">
              {{ $t('Login.This user is already signed in on another location') }}
            </div>
            <div class="form-check mb-3" v-show="showForceLogout">
              <input class="form-check-input" type="checkbox" value="1" id="logout_devices" v-model="forceLogout" tabindex="3">
              <label class="form-check-label" for="logout_devices">
                {{ $t('Login.Sign out on other devices') }}
              </label>
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="!showError && $route.query.session_expired === 'true'">
              {{ $t('Login.Session is expired') }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showError">
              {{ $t('Login.Email address or password incorrect') }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="attemptsLeft">
              {{ $t('Login.Only {ATTEMPTS} attempts left for this {REASON}', { ATTEMPTS: attemptsLeft + 1, REASON: typeOfBlock }) }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="attemptsLeft === 0">
              {{ $t('Login.Last attempt before this {REASON} will be blocked for 5 minutes', { REASON: typeOfBlock }) }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="timeLeft">
              {{ $t('Login.Too many login attempts have been made') }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="timeLeft" v-if="typeOfBlock === 'IP'">
              {{ $t('Login.The IP address has been blocked for {MINUTES}:{SECONDS} minutes', { MINUTES: String(Math.floor(timeLeft / 60)).padStart(2, '0'), SECONDS: String(timeLeft % 60).padStart(2, '0') }) }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="timeLeft" v-if="typeOfBlock === 'username'">
              {{ $t('Login.The username has been blocked for {MINUTES}:{SECONDS} minutes', { MINUTES: String(Math.floor(timeLeft / 60)).padStart(2, '0'), SECONDS: String(timeLeft % 60).padStart(2, '0') }) }}
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showLicenseError" v-html="$t('Login.You are not licensed for this product, contact your supplier for more information')"></div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showBlockedError" v-html="$t('Login.You are currently not allowed to sign in, contact your administrator')"></div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showSSOStateError" v-html="$t('Login.Single Sign On failed due to a configuration error or a timeout')"></div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showSSOIdError" v-html="$t('Login.Single Sign On failed, this account is already configured for SSO with a different user')"></div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showSSOSiteError" v-html="$t('Login.Single Sign On failed, user not attached to a site, contact your administrator')"></div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showSSOMeetingError" v-html="$t('Login.Single Sign On meeting creation failed, contact your administrator')"></div>
            <div class="d-flex">
              <button type="submit" class="btn btn-lg btn-primary btn-block" :class="{ disabled: loginButtonDisabled }" :disabled="loginButtonDisabled" v-if="loginButtonLoading === false" tabindex="4">{{ $t('Login.Sign in') }}</button>
              <button type="submit" class="btn btn-lg btn-primary btn-block disabled" disabled="disabled" v-else><font-awesome-icon :icon="['fal', 'spinner']" spin role="presentation" /> {{ $t('Login.Signing in') }}</button>
            </div>
          </form>

          <form v-else class="d-flex flex-column" method="post" @submit.prevent="validateMFA">
            <p v-if="mfaQR" v-html="$t('Login.Scan the code below with the authenticator app')"></p>
            <p v-else v-html="$t('Login.Fill in the code from the authenticator app')"></p>
            <img v-if="mfaQR" :src="mfaQR" class="align-self-center mb-3" alt="QR" />
            <div class="form-group">
              <label for="mfa" class="mb-0 font-weight-bold">{{ $t('Login.MFA Code') }}</label>
              <input spellcheck="false" type="text" class="form-control" id="mfa" name="mfa" ref="mfa" v-model="mfaCode" tabindex="1">
            </div>
            <div class="form-row text-danger font-size-rem-9 justify-content-center text-center mb-1" v-show="showMfaError">
              {{ $t('Login.Code is incorrect') }}
            </div>
            <div class="d-flex">
              <button @click="cancelMFA()" type="button" class="btn btn-lg btn-blue" tabindex="4">{{ $t('Login.Cancel') }}</button>
              <button type="submit" class="btn btn-lg btn-primary btn-block ml-2" :class="{ disabled: mfaButtonDisabled }" :disabled="mfaButtonDisabled" v-if="mfaButtonLoading === false" tabindex="5">{{ $t('Login.Sign in') }}</button>
              <button type="submit" class="btn btn-lg btn-primary btn-block disabled ml-2" disabled="disabled" v-else><font-awesome-icon :icon="['fal', 'spinner']" spin role="presentation" /> {{ $t('Login.Signing in') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qrcode from 'qrcode'
import { authenticator } from 'otplib'
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      loginButtonLoading: false,
      loginButtonDisabled: true,
      mfaButtonLoading: false,
      mfaButtonDisabled: true,
      showError: false,
      attemptsLeft: false,
      typeOfBlock: false,
      timeLeft: false,
      timeLeftCountdown: false,
      forceLogout: false,
      showBlockedError: false,
      showForceLogout: false,
      showLicenseError: false,
      showProductError: false,
      mfaRequired: false,
      mfaSecret: null,
      mfaQR: null,
      mfaCode: '',
      showMfaError: false,
      showSSOStateError: false,
      showSSOIdError: false,
      showSSOSiteError: false,
      showSSOMeetingError: false,
      state: null
    }
  },
  methods: {
    disableViewPassword (ref) {
      if (this.$refs[ref].type === 'text') {
        this.$refs[ref].togglePassword(false)
      }
    },
    isValidProduct (product, hasFeature) {
      return (
        (product === 'direct' && (hasFeature('weseedo_direct') || hasFeature('weseedo_direct_2way'))) ||
        (product === 'personal' && hasFeature('weseedo_personal'))
      )
    },
    navigateUser (product, user, userSites) {
      const shouldShowPreview = userSites.some(site =>
        site.enabled_features.includes('preview_screen')
      )
      if (product === 'direct') {
        this.$router.push({ name: 'appointment' })
      } else if (product === 'personal') {
        if (user.role === 'assistent') {
          this.$router.push({ name: 'waiting_room' })
        } else {
          this.$router.push({ name: shouldShowPreview ? 'meeting' : 'waiting_room' })
        }
      }
    },
    handleLoginError () {
      this.$axios.logout(false)
      this.loginButtonLoading = false
      this.showLicenseError = true
    },
    processLogin () {
      const product = this.$store.getters.getProduct
      const user = this.$store.getters.getUser
      const hasFeature = this.$store.getters.userHasFeature
      const userSites = this.$store.getters.getUserSites
      if (this.isValidProduct(product, hasFeature)) {
        this.$webrtc.connect()
        this.loginButtonLoading = false
        this.$root.getNotificationBar()
        this.navigateUser(product, user, userSites)
      } else {
        this.handleLoginError()
      }
    },
    async cancelMFA () {
      this.$axios.logout(false)
      this.$webrtc.disconnect(false)
      Object.assign(this.$data, this.$options.data.call(this))
    },
    async validateMFA () {
      this.showMfaError = false

      let auth
      if (this.mfaSecret) {
        auth = await this.$axios.setup_mfa(this.mfaSecret, this.mfaCode)
      } else {
        auth = await this.$axios.validate_mfa(this.mfaCode)
      }
      if (typeof auth.status !== 'undefined' && auth.status === 200) {
        this.processLogin()
      } else {
        this.showMfaError = true
      }
    },
    async validateLogin () {
      this.loginButtonLoading = true
      this.showError = false

      this.showBlockedError = false
      this.showForceLogout = false
      this.showLicenseError = false
      this.showProductError = false
      this.showSSOStateError = false
      this.showSSOIdError = false
      this.showSSOSiteError = false
      this.showSSOMeetingError = false
      this.attemptsLeft = false
      this.typeOfBlock = false
      this.timeLeft = false

      const auth = await this.$axios.login(this.username, this.password, this.forceLogout, this.state)
      console.log('AUTH DATA, SHOULD BE A REDIRECT: ')
      console.log(auth)
      if (auth.redirect) {
        console.log('REDIRECT TO: ', auth.redirect)
        window.location = auth.redirect
      } else if (auth.require_mfa && !auth.secret) {
        this.mfaRequired = true
      } else if (auth.require_mfa && auth.secret) {
        const otpauth = authenticator.keyuri(this.username, 'WeSeeDo', auth.secret)
        qrcode.toDataURL(otpauth, (err, imageUrl) => {
          if (err) {
            console.log('Error with QR')
            return
          }
          this.mfaRequired = true
          this.mfaSecret = auth.secret
          this.mfaQR = imageUrl
        })
      } else if (typeof auth.status !== 'undefined' && auth.status === 200) {
        console.log('AUTH WAS SUCCESSFULL')
        this.processLogin()
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('ALREADY_LOGGED_IN_ELSEWHERE')) {
        this.showForceLogout = true
        this.loginButtonLoading = false
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('USER_BLOCKED')) {
        this.showBlockedError = true
        this.loginButtonLoading = false
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('LICENSE_ERROR')) {
        this.showLicenseError = true
        this.loginButtonLoading = false
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('SSO_WRONG_STATE')) {
        this.showSSOStateError = true
        this.loginButtonLoading = false
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('SSO_WRONG_ID')) {
        this.showSSOIdError = true
        this.loginButtonLoading = false
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('SSO_SITE_NOT_FOUND')) {
        this.showSSOSiteError = true
        this.loginButtonLoading = false
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('SSO_MEETING_FAILED')) {
        this.showSSOMeetingError = true
        this.loginButtonLoading = false
      } else {
        if (auth.status === 401) {
          this.attemptsLeft = auth.data.result
          this.typeOfBlock = auth.data.error_message
        }
        if (auth.status === 429) {
          this.timeLeft = auth.data.result
          this.typeOfBlock = auth.data.error_message
          clearInterval(this.timeLeftCountdown)
          this.timeLeftCountdown = setInterval(() => {
            if (this.timeLeft) {
              this.timeLeft -= 1
            } else {
              this.timeLeft = false
              this.showError = false
              clearInterval(this.timeLeftCountdown)
            }
          }, 1000)
        }
        this.showError = true
        this.loginButtonLoading = false
      }
    }
  },
  computed: {
    watchUserPassInputs () {
      return [this.username, this.password]
    }
  },
  watch: {
    watchUserPassInputs () {
      this.loginButtonDisabled = !(this.username !== '' && this.password !== '')
    },
    mfaCode () {
      this.mfaButtonDisabled = !(this.mfaCode !== '')
    }
  },
  mounted () {
    this.$store.commit('setCamSettings', {})

    this.$root.getNotificationBar()

    this.$noSleep.disable()
    this.$webrtc.resetIsInitialConnection()
    if (this.$route.query.state) {
      this.state = this.$route.query.state
    }

    if (window.location.hostname === 'sandbox-ciz.weseedo.nl') {
      window.location.href = 'https://sandbox-api.weseedo.nl/api/v2/sso/ciz/callback'
      return true
    } else if (window.location.hostname === 'login-ciz.weseedo.nl') {
      window.location.href = 'https://api.weseedo.nl/api/v2/sso/ciz/callback'
      return true
    } else if (window.location.hostname === 'login-mknn.weseedo.nl') {
      window.location.href = 'https://login.mknn.nl/adfs/ls/?wtrealm=https%3A%2F%2Fapi-mknn.weseedo.nl%2Fapi%2Fv2%2Fsso%2Fmknn%2Fcallback%2F&wa=wsignin1.0'
      return true
    } else if (window.location.hostname === 'sandbox-utrecht.weseedo.nl') {
      window.location.href = 'https://sandbox-api.weseedo.nl/api/v2/sso/609be602a43a515845b3483b/callback'
      return true
    } else if (window.location.hostname === 'videobellen.utrecht.nl' || window.location.hostname === 'videobellenwsd.utrecht.nl') {
      window.location.href = 'https://api.weseedo.nl/api/v2/sso/60af9a13cc0a9b19bdd7365d/callback'
      return true
    } else if (window.location.hostname === 'sandbox-zoetermeer.weseedo.nl') {
      window.location.href = 'https://sandbox-api.weseedo.nl/api/v2/sso/645cbadda3d8504a1d226c40/callback'
      return true
    } else if (window.location.hostname === 'beeldbellen.zoetermeer.nl') {
      window.location.href = 'https://api.weseedo.nl/api/v2/sso/647db598c8ebb2b41b063cea/callback'
      return true
    } else if (window.location.hostname === 'sandbox-revalidatie-friesland.weseedo.nl') {
      window.location.href = 'https://sandbox-api.weseedo.nl/api/v2/sso/633e9147005b0f3618e204fd/callback'
      return true
    } else if (window.location.hostname === 'beeldbellen.revalidatie-friesland.nl') {
      window.location.href = 'https://api.weseedo.nl/api/v2/sso/63035628ce986506b96d4a34/callback'
      return true
    }

    this.$refs.email.focus()
    this.$webrtc.disconnect(false)
  }
}
</script>

<style lang="scss" scoped>

</style>
