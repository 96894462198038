import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'
import { extractQueryParams } from './urlHelper'
import { Upload } from 'tus-js-client'
import { handleWebmToMp3Conversion } from './lame'

const $v = new Vue()

axios.defaults.withCredentials = true

const axiosInstance = {}
const axiosParticipantInstance = {}
const axiosSiteInstance = {}
const axiosAdminInstance = {}
const axiosLoggerInstance = {}

// Routes
axiosInstance.routes = {
  login: '/auth/login',
  setup_mfa: '/auth/setup_mfa',
  validate_mfa: '/auth/validate_mfa',
  logout: '/auth/logout',
  meetingLogin: '/auth/meeting_token',
  getUser: '/auth/me',
  colleagues: '/auth/colleagues',
  meeting: '/direct/meetings',
  meeting_personal: '/personal/meetings',
  meeting_personal_recurring: '/personal/recurring_meetings',
  account: '/management/sites',
  forgot_password: '/auth/forgot_password',
  reset_password: '/auth/reset',
  feedback: '/management/feedback',
  set_logout: '/auth/set-logout'
}

axiosParticipantInstance.routes = {
  feedback: '/management/feedback',
  meeting_personal: '/personal/meetings'
}

axiosSiteInstance.routes = {
  login_site: '/auth/site_login',
  meeting_personal: '/personal/meetings',
  button: '/personal/button',
  site_overview: '/management/sites_overview',
  site: '/management/sites'
}

axiosAdminInstance.routes = {
  getNotifications: 'api/messages'
}

// Instances
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    'Authorization': 'Bearer ' + store.state.access_token,
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  response => response,
  err => {
    console.log('Axios run interceptors')

    if (err.response.status === 401) {
      axiosInstance.logout(false)

      if (router.authenticatedRoutes.includes(router.history.current.name) || router.history.current.name === 'meeting') {
        // router.push({ name: 'login', query: { session_expired: 'true' } })
        window.location.href = '/login?session_expired=true'
      }
    }

    return Promise.reject(err.response)
  }
)

const participantInstance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  withCredentials: false,
  headers: {
    'Authorization': 'Bearer ' + store.state.participant_access_token,
    'Content-Type': 'application/json'
  }
})

const siteInstance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  withCredentials: false,
  headers: {
    'Authorization': 'Bearer ' + store.state.site_access_token,
    'Content-Type': 'application/json'
  }
})

const adminInstance = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_HOST,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Set token functions
axiosSiteInstance.setToken = (token) => {
  siteInstance.defaults.headers.Authorization = 'Bearer ' + token
}

// POST, GET, PUT, PATCH, DELETE functions
axiosSiteInstance.post = (url, data) => {
  console.log('Axios run siteInstance POST', url)

  return siteInstance.post(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosSiteInstance.get = (url, query = '') => {
  console.log('Axios run siteInstance GET', url)

  return siteInstance.get(process.env.VUE_APP_API_HOST + url, query).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosParticipantInstance.post = (url, data) => {
  console.log('Axios run POST', url)

  return participantInstance.post(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosParticipantInstance.get = (url, query = '') => {
  console.log('Axios run GET', url)

  return participantInstance.get(process.env.VUE_APP_API_HOST + url, query).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosParticipantInstance.put = (url, data) => {
  console.log('Axios run PUT', url)

  return participantInstance.put(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosParticipantInstance.patch = (url, data) => {
  console.log('Axios run PATCH', url)

  return participantInstance.patch(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosParticipantInstance.delete = (url, id, queryStringParameters) => {
  console.log('Axios run delete', url)
  if (typeof queryStringParameters === 'undefined') {
    queryStringParameters = ''
  }

  return participantInstance.delete(process.env.VUE_APP_API_HOST + url + '/' + id + queryStringParameters).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.post = (url, data, config = {}) => {
  console.log('Axios run POST', url)

  return instance.post(process.env.VUE_APP_API_HOST + url, data, config).then(response => {
    return response
  }).catch(err => {
    throw err
  })
}

axiosInstance.get = (url, query = '') => {
  console.log('Axios run GET', url)

  return instance.get(process.env.VUE_APP_API_HOST + url, query).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.put = (url, data) => {
  console.log('Axios run PUT', url)

  return instance.put(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.patch = (url, data) => {
  console.log('Axios run PATCH', url)

  return instance.patch(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.delete = (url, id, queryStringParameters) => {
  console.log('Axios run delete', url)
  if (typeof queryStringParameters === 'undefined') {
    queryStringParameters = ''
  }

  return instance.delete(process.env.VUE_APP_API_HOST + url + '/' + id + queryStringParameters).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

// Manual functions
axiosInstance.login = (username, password, forceLogout, state) => {
  console.log('Axios run login')

  axiosInstance.logout(false)

  store.commit('enableUINavigation', true)
  store.commit('setMeetingEndedAs', null)
  store.commit('setMeetingDisconnectedAs', null)
  store.commit('setLastMeetingInfo', {})

  let data = {
    'product': store.getters.getProduct,
    'username': username.toLowerCase(),
    'password': password,
    'force_logout': forceLogout,
    'lifetime': 3600 * 10
  }
  if (state) {
    data.state = state
  }
  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.login, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    store.commit('setAccessToken', response.data.access_token)
    instance.defaults.headers.Authorization = 'Bearer ' + response.data.access_token

    console.log('AUTH RESPONSE')
    console.log(response)
    if (response.data && response.data.post_login_redirect) {
      console.log('GOT A REDIRECT RESPONSE')
      console.log(response.data.post_login_redirect)
      return {
        redirect: response.data.post_login_redirect
      }
    } else {
      if (response.data.require_mfa) {
        return {
          require_mfa: true,
          secret: response.data.secret ? response.data.secret : null
        }
      } else {
        const me = await axiosInstance.getUser()
        return me
      }
    }
  }).catch(err => {
    axiosInstance.logout()

    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.setup_mfa = (secret, code) => {
  console.log('Axios run setup mfa')

  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.setup_mfa, {
    'product': store.getters.getProduct,
    'secret': secret,
    'code': code
  }, {
    headers: {
      'Authorization': 'Bearer ' + store.state.access_token,
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    console.log('RESP', response)
    store.commit('setAccessToken', response.data.result.access_token)
    instance.defaults.headers.Authorization = 'Bearer ' + response.data.result.access_token

    const me = await axiosInstance.getUser()
    return me
  }).catch(err => {
    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.validate_mfa = (code) => {
  console.log('Axios run validate mfa')

  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.validate_mfa, {
    'product': store.getters.getProduct,
    'code': code
  }, {
    headers: {
      'Authorization': 'Bearer ' + store.state.access_token,
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    store.commit('setAccessToken', response.data.result.access_token)
    instance.defaults.headers.Authorization = 'Bearer ' + response.data.result.access_token

    const me = await axiosInstance.getUser()
    return me
  }).catch(err => {
    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.autoLogin = async (token) => {
  console.log('Axios run autoLogin')

  axiosInstance.logout(false)

  store.commit('enableUINavigation', true)
  store.commit('setMeetingEndedAs', null)
  store.commit('setMeetingDisconnectedAs', null)
  store.commit('setLastMeetingInfo', {})

  store.commit('setAccessToken', token)

  instance.defaults.headers.Authorization = 'Bearer ' + token

  const me = await axiosInstance.getUser()

  return me
}

axiosInstance.meetingLogin = (token) => {
  console.log('Axios run meetingLogin')

  axiosInstance.logout(false)

  store.commit('enableUINavigation', false)
  store.commit('setMeetingEndedAs', null)
  store.commit('setMeetingDisconnectedAs', null)
  store.commit('setLastMeetingInfo', {})

  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.meetingLogin, {
    'meeting_token': token
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    // TODO: set product based on features
    // store.commit('setProduct', 'personal')

    if (response.data.result.request_type === 'agent') {
      // add meeting_token into response for saving in store
      response.data.result.meeting.meeting_token = token

      instance.defaults.headers.Authorization = 'Bearer ' + response.data.result.access_token

      store.commit('setAccessToken', response.data.result.access_token)
      store.commit('setMeeting', response.data.result.meeting)

      await axiosInstance.getUser()

      return response
    } else {
      // add meeting_token into response for saving in store
      response.data.result.meeting.meeting_token = token

      participantInstance.defaults.headers.Authorization = 'Bearer ' + response.data.result.access_token

      store.commit('setParticipantAccessToken', response.data.result.access_token)
      store.commit('setMeeting', response.data.result.meeting)
      store.commit('setLocale')

      return response
    }
  }).catch(err => {
    axiosInstance.logout()

    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.getUser = (pushRouterWhenFailed = false) => {
  console.log('Axios run getUser')

  return axiosInstance.get(axiosInstance.routes.getUser).then(response => {
    if (JSON.stringify(response.data.result) !== JSON.stringify(store.getters.getUser)) {
      store.commit('setUser', response.data.result)
      store.commit('setLocale')
    }

    // TODO: set product based on features
    // store.commit('setProduct', 'personal')

    window.loginTimer = setTimeout(() => axiosInstance.getUser(true), 30000)

    return response
  }).catch(err => {
    if (typeof err.response !== 'undefined') {
      axiosInstance.logout()

      if (pushRouterWhenFailed === true && (router.authenticatedRoutes.includes(router.history.current.name) || router.history.current.name === 'meeting')) {
        // router.push({ name: 'login', query: { session_expired: 'true' } })
        window.location.href = '/login?session_expired=true'
      }

      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.logout = async function (sendToAPI = true) {
  console.log('Axios run logout')

  const currentToken = store.state.access_token

  clearTimeout(window.loginTimer)
  window.loginTimer = null

  store.commit('setUser', {})
  store.commit('setMeeting', {})
  store.commit('setMeetingStarted', false)
  store.commit('setAccessToken', null)
  store.commit('setParticipantAccessToken', null)
  store.commit('setSiteAccessToken', null)
  store.commit('setLocale')
  // store.commit('setProduct', false)
  store.commit('setStatus', null)

  delete instance.defaults.headers.Authorization

  let axiosResponse = true

  if (sendToAPI === true && currentToken !== null) {
    axiosResponse = await axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.logout, {}, {
      headers: {
        'Authorization': 'Bearer ' + currentToken,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      return response
    }).catch(err => {
      if (typeof err.response !== 'undefined') {
        return err.response
      } else {
        return err
      }
    })
  }

  return axiosResponse
}

axiosInstance.meetingLogout = () => {
  console.log('Axios run meetingLogout')

  store.commit('setMeeting', {})
  store.commit('setDeviceList', {})
  store.commit('setMeetingStarted', false)
  // store.commit('setParticipantAccessToken', null)
  // store.commit('setProduct', false)
}

axiosAdminInstance.getNotifications = () => {
  console.log('Axios run getNotifications')

  const currentToken = store.state.access_token
  let config = {}

  if (currentToken) {
    config = {
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + currentToken,
        'Content-Type': 'application/json'
      }
    }
  }

  return adminInstance.get(process.env.VUE_APP_MESSAGES_API_HOST + axiosAdminInstance.routes.getNotifications + '/' + store.getters.getProduct, config).then(response => {
    return response
  }).catch(err => {
    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}
/**
 * This uploads recording to Bumicom and returns the response
 * @param {*} file
 * @param {*} fileName
 * @returns
 */
axiosInstance.uploadRecordingToBumicom = async (file, fileName) => {
  console.log('Axios run uploadRecording')
  const queryParams = extractQueryParams(window.location.href)
  const formData = new FormData()
  formData.append('apiKey', process.env.VUE_APP_BUMICON_API_KEY)
  formData.append('file', file, fileName)
  formData.append('dateStart', new Date().toISOString())
  if (queryParams && Object.keys(queryParams).length > 0) {
    for (const key in queryParams) {
      if (Object.hasOwnProperty.call(queryParams, key)) {
        if (key === 'callId') {
          formData.append('callIdentifier', queryParams[key])
        } else {
          formData.append(key, queryParams[key])
        }
      }
    }
  }

  try {
    const response = await axios.post(process.env.VUE_APP_BUMICON_API, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: false
    })
    return response
  } catch (err) {
    console.log(err, 'err')
    throw err
  }
}

axiosInstance.uploadRecordingToOSAW = async (file) => {
  const companyHasOSAW = store.getters.companyHasFeature('osaw_transcription')
  if (companyHasOSAW) {
    $v.$toast.add({ severity: 'info', detail: 'Processing File...' })
    const mp3Base64 = await handleWebmToMp3Conversion(file)
    if (!mp3Base64) {
      $v.$toast.add({ severity: 'error', detail: 'Unable to convert file', life: 3000 })
      return
    }
    $v.$toast.removeAllGroups()
    $v.$toast.add({ severity: 'info', detail: 'File uploading in progress' })

    const meetingId = store.getters.getMeeting.id

    try {
      const response = await axiosInstance.post('/personal/meetings/upload_osaw_recording', {
        meetingId,
        mp3Base64
      })
      if (response.status === 200) {
        $v.$toast.removeAllGroups()
        $v.$toast.add({ severity: 'success', detail: 'File uploaded successfully', life: 3000 })
      }
    } catch (error) {
      $v.$toast.removeAllGroups()
      $v.$toast.add({ severity: 'error', detail: 'Unable to upload file', life: 3000 })
    }
  }
}

axiosInstance.uploadRecordingToScriptix = async (file, fileName, progressToast, apiKey) => {
  console.log('Axios run uploadRecordingToScriptix')

  const webhookData = JSON.stringify({
    webhook_headers: [`Authorization: Bearer ${store.state.access_token}`],
    webhook_method: 'POST',
    webhook_url: `${process.env.VUE_APP_SCRIPTIX_CALLBACK_URL}/${store.state?.meeting?.id}`
  })
  console.log('webhookData', webhookData)
  try {
    const toast = document.querySelector('#scriptix2-toast')
    const title = toast.querySelector('#uploading-title')
    const progressBar = toast.querySelector('progress')
    title.textContent = 'Transcriptie opslaan in Scriptix'
    // Create a new tus upload
    const upload = new Upload(file, {
      endpoint: process.env.VUE_APP_SCRIPTIX_API,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      headers: { 'x-zoom-s2t-key': `${apiKey}`
      },
      metadata: {
        filename: fileName,
        filetype: file.type,
        language: 'NL',
        document_type: 'document',
        punctuation: true,
        keep_source: 'true',
        multichannel: 'false',
        document: webhookData
      },
      onError: function (error) {
        console.log('Failed because: ' + error)
        title.textContent = 'Transcriptie upload is mislukt'
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0)

        if (progressBar.style.display === 'none') {
          progressBar.style.display = 'block'
        }

        progressBar.value = `${percentage}`

        if (percentage === '100.00') {
          progressBar.style.backgroundColor = 'green'
        }
      },
      onSuccess: function () {
        title.textContent = 'Upload transcriptie is geslaagd'
        setTimeout(() => {
          progressBar.value = `0`
          progressBar.style.display = 'none'
          progressToast.toast('hide')
        }, 3000)
      }
    })

    // // Check if there are any previous uploads to continue.
    const previousUploads = await upload.findPreviousUploads()
    if (previousUploads.length) {
      upload.resumeFromPreviousUpload(previousUploads[0])
    }

    // Start the upload
    upload.start()
  } catch (err) {
    console.log(err, 'err')
    throw err
  }
}

axiosInstance.downloadScriptixTranscript = async (sessionId, documentId, apiKey, onProgress) => {
  console.log('Axios run downloadScriptixTranscript')
  try {
    const url = process.env.VUE_APP_SCRIPTIX_DOWNLOAD_URL
      .replace(':sessionId', sessionId)
      .replace(':documentId', documentId)

    const response = await axios.get(url, {
      responseType: 'blob',
      headers: { 'x-zoom-s2t-key': `${apiKey}` },
      onDownloadProgress: (progressEvent) => {
        if (onProgress && typeof onProgress === 'function') {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onProgress(percentCompleted)
        }
      }
    })
    return response
  } catch (err) {
    console.log(err, 'err')
    throw err
  }
}

axiosInstance.deleteScriptixTranscript = async (sessionId, documentId, apiKey) => {
  console.log('Axios run deleteScriptixTranscript')
  try {
    const url = process.env.VUE_APP_SCRIPTIX_DOWNLOAD_URL
      .replace(':sessionId', sessionId)
      .replace(':documentId', documentId)
    const response = await axios.delete(url, {
      headers: { 'x-zoom-s2t-key': `${apiKey}` }
    })
    return response
  } catch (err) {
    console.log(err, 'err')
    throw err
  }
}

export { axios, axiosInstance, axiosSiteInstance, axiosAdminInstance, axiosLoggerInstance, axiosParticipantInstance }
