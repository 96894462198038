<template>
    <transition name="fade">
        <div class="dialog" :style="dialogStyle" ref="scriptixDialog">
            <div class="dialog-body" :class="{ expanded: isDialogContentVisible }" ref="dialogBody">
                <div class="dialog-header">
                    <h6>{{ $t('Modals/ScriptixTranscription.Title') }}</h6>
                    <div>
                        <button type="button" class="expand" @click="toggleDialogContent" aria-label="expand dialog"
                            ref="expandButton">
                            <font-awesome-icon :icon="['fal', 'angle-up']" class="fa-xs expandButton" />
                        </button>
                        <button type="button" class="close" @click="$emit('close')"
                            :aria-label="$t('Accessibility/General.Close')">
                            <font-awesome-icon :icon="['fal', 'xmark']" class="fa-xs" />
                        </button>
                    </div>
                </div>
                <hr>
                <transition name="fade">
                    <div class="dialog-content" v-if="isDialogContentVisible">
                        <p class="p-2" v-html="$t('Modals/ScriptixTranscription.description')"></p>
                        <div class="dialog-footer">
                            <div class="loader" :style="loaderStyle" v-if="notification.progress > 0"></div>
                            <button @click="triggerDownloadAndDelete" class="primaryDownload btn btn-lg btn-primary" :disabled="notification.progress > 0">
                                <span>{{ $t('Modals/ScriptixTranscription.Download') }}</span>
                            </button>
                            <button @click="$emit('close')" class="btn btn-secondary">{{
                                $t('Modals/ScriptixTranscription.Close') }}</button>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
import DialogMixin from '../mixin/DialogMixin.vue'
export default {
  mixins: [DialogMixin],
  props: {
    notification: {
      type: Object,
      required: false
    },
    notificationCount: {
      type: Number,
      required: false,
      default: 0
    },
    index: {
      type: Number,
      required: false
    }
  },
  methods: {
    triggerDownloadAndDelete () {
      this.$emit('triggerScriptixTranscription', this.notification.data['x-scriptix-session'], this.notification.data['x-scriptix-document'], this.index)
    }
  },
  watch: {
    'notification.progress': function (newVal) {
      if (newVal === 100) {
        this.$emit('close')
      }
    }
  }
}
</script>
